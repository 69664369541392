import { Image } from 'primereact/image';
import React from 'react';
// import LogoWhite from '../../assets/icons/logo-white.png';
// import LogoWhite from '../../assets/images/new-logo.webp';

const PerformanceCheckReportHeader = ({ mini = false }) => {
  return (
    <header className={`paper_header ${mini ? 'mini' : ''}`}>
      <div className="">
        {/* <Image className="" src={LogoWhite} alt="logo" width="30" /> */}
        <p className="">HANDOK</p>
      </div>
      <p className="text-2xl">한독자동차㈜</p>
    </header>
  );
};

export default PerformanceCheckReportHeader;
