import React, { forwardRef } from 'react';

import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';

export const GroupInputText = forwardRef((props, ref) => {
  const {
    id,
    value,
    onChange,
    error = null,
    readOnly = false,
    suffix = null,
    className = '',
    onKeyDown,
    onFocus,
    placeholder,
  } = props;

  return (
    <div className="p-inputgroup h-full">
      <div className="flex w-full h-full">
        <InputText
          placeholder={placeholder}
          ref={ref}
          className={classNames(
            className,
            'w-full',
            readOnly && (className === '' ? 'bg-gray-100' : className)
          )}
          readOnly={readOnly}
          value={suffix ? `${value} ${suffix}` : value}
          onChange={onChange && ((e) => onChange(e))}
          // suffix={suffix || ''}
          onKeyDown={onKeyDown && ((e) => onKeyDown(e))}
          onFocus={onFocus && ((e) => onFocus(e))}
        />
      </div>
    </div>
  );
});
